const dev = {
	baseURL: "http://localhost:3080/api/",
	landingPageUrl: "http://localhost:3080",
	stripe: {
		free: "price_1MP5pmC4gpAfet0jSlSAxYOK",
		entry: "price_1MP3iZC4gpAfet0j167o41rX",
		pro: "price_1MP5kzC4gpAfet0j9zefq8Yv"
	},
};
  
const prod = {
	baseURL: '/api/',
	landingPageUrl: "https://ent.topmarks.ai",
	stripe: {
		free: "price_1MPoKTC4gpAfet0jJMUsoTjh",
		entry: "price_1MPoMXC4gpAfet0j4sTl1jU5",
		pro: "price_1MPoNmC4gpAfet0jFRBiUMTo"
	},
};
  
const config = process.env.NODE_ENV === 'development'
	? dev
	: prod;
  
export default config;