import Loader from './Loader'
import Typist from 'react-typist';
import {  CheckIcon,
} from '@heroicons/react/solid'
import {   DuplicateIcon, ExclamationCircleIcon, DownloadIcon
} from '@heroicons/react/outline'
import styled from 'styled-components'
import { observer, inject } from 'mobx-react'
import { saveAs } from 'file-saver';
import CodeEditor from "@uiw/react-textarea-code-editor";
import { useEffect, useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { io } from "socket.io-client";


const { connectedSockets, setCurrentSocketId, getCurrentSocketId } = require('../utils/sharedSockets');

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');
  
  .uiw-react-textarea-code-editor {
    font-family: 'Roboto', sans-serif !important;
  }
`;


export const Output = inject('store')(observer(({ store, title, desc, Icon, output, code, language, outputs, loading, children, fromColor, toColor, outputsColor, OutputsIcon, scores }) => {
  	const [ws, setWs] = useState(null);
  	const [gpt4Data, setGpt4Data] = useState(null);
const [gpt3TurboData, setGpt3TurboData] = useState(null);
const [progressData, setProgressData] = useState(null);
const [runningTotalsData, setRunningTotalsData] = useState(null);
const [editorValue, setEditorValue] = useState("");

useEffect(() => {
	setEditorValue(gpt4Data);
  }, [gpt4Data]);

  function handleEditorChange(newValue) {
    setEditorValue(newValue);
  }


useEffect(() => {
	const socketUrl = process.env.REACT_APP_SOCKET_URL || "https://server-xsbs.onrender.com:443";
	const socket = io(socketUrl, { secure: true });
	// Set the current socket ID when the component is mounted
  
	socket.on("connect", () => {
	  console.log("Connected to Socket.IO server");
	  setCurrentSocketId(socket.id);

	});

	socket.on("register_socket", (event) => {
		console.log("Received socket.id from server:", event.socketId);
		connectedSockets[event.socketId] = socket; // Store the connected socket in the connectedSockets object
		console.log("Current connectedSockets:", connectedSockets);
	  });
	  
	// Handle the welcome message from the server
	socket.on("welcome", (message) => {
	  console.log(message);
	});
  
	// Handle messages from the server
	socket.on("gpt4", (event) => {
		console.log("internal llm data:", event.data);
		setGpt4Data(event.data);
	  });
	  
	  socket.on("progress", (event) => {
		console.log("Progress data:", event.data);
		setProgressData(event.data);
	  });
	  
	  socket.on("running_totals", (event) => {
		setRunningTotalsData(event.data);
	  });
	  
	  socket.on("gpt3turbo", (event) => {
		console.log("algorithmic data:", event.data);
		setGpt3TurboData(event.data);
	  });
  
	// Handle errors
	socket.on("error", (error) => {
	  console.error(`Socket.IO error: ${error}`);
	});
  
	// Handle socket closing
	socket.on("disconnect", () => {
	  console.log("Client disconnected");
	});
  
	return () => {
	  socket.disconnect();
	};
  }, []);
	
	return (
	  <>
		{scores && (
  <div className="bg-white rounded-lg shadow-md p-7 mb-10 border border-gray-300">
    <AOScores scores={scores} gpt3TurboData={gpt3TurboData} progressData={progressData} />
  </div>
)}
		<div className="relative mb-12">
		  <GlobalStyle />
		  <div
			className={`absolute inset-0 bg-gradient-to-r from-${fromColor ? fromColor : 'green-400'} to-${toColor ? toColor : 'blue-500'} shadow-lg transform md:skew-y-0 md:-rotate-3 md:rounded-3xl -mt-1 md:mt-0`}
		  ></div>
		  <div className="align-bottom bg-white md:rounded-3xl text-left shadow-xl transform transition-all sm:align-middle transition shadow-md hover:shadow-2xl focus:shadow-2xl">
			<div className="px-6 py-6">
			  <div className="sm:flex sm:items-start">
			  {loading && !gpt3TurboData ? (
				  <>
					<Loader active={loading} className="w-10 h-10" />
				  </>
				) : (
				  <>
					<div
					  className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-${
						gpt4Data ? 'green' : 'gray'
					  }-300 sm:mx-0 sm:h-10 sm:w-10 bg-gradient-to-r from-${fromColor ? fromColor : 'green-400'} to-${
						toColor ? toColor : 'blue-500'
					  }`}
					>
					  {Icon ? <Icon className={`h-6 w-6 text-white`} aria-hidden="true" /> : null}
					</div>
				  </>
				)}
	
				<div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
				  <div as="h3" className="text-lg leading-6 font-medium text-gray-900">
					{title}
				  </div>
				  <p className="text-sm text-gray-500">{desc}</p>
				</div>
			  </div>
			  {gpt4Data ? (
        <div className="whitespace-pre-wrap min-w-full text-gray-800 h-auto text-lg divide-y px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <CodeEditor
            style={{
              fontFamily: "Roboto, sans-serif",
              fontSize: "1.2rem",
            }}
            padding={10}
            value={editorValue}
            onChange={handleEditorChange}
          />
        </div>
      ) : null}



			  {gpt4Data && outputs && outputs.length ? (
				<div className="divide-y divide-dashed divide-gray-300">
				  <div></div>
				  <div></div>
				</div>
			  ) : null}
  
			  {outputs && outputs.length ? (
				<Outputs
				  outputs={outputs}
				  outputsColor={outputsColor}
				  OutputsIcon={OutputsIcon}
				/>
			  ) : null}
  
			  {code && code.length ? (
				<CodeEditor
				  style={{
					fontFamily: 'Roboto, sans-serif',
					fontSize: '1rem',
				  }}
				  padding={30}
				  language={language}
				  value={code}
				/>
			  ) : null}
			  <QuickTools outputs={outputs} output={gpt4Data} code={code} />
			</div>
		  </div>
		</div>
	  </>
	);
  }));




export const QuickTools = inject('store')(observer(({ store, output, outputs, code }) => {
	const title = output || code || outputs ? 'my-output' : '';
	return (
		<>
{(output || code || (outputs && outputs.length)) ? <div className="flex">
			
			<Shortcut className="p-1 rounded-lg cursor-pointer hover:bg-green-200 hover:text-green-700 relative group flex flex-col items-center group text-gray-300"
			onClick={()=>store.copyToClipboard(output || code || outputs)}
			>
				<DuplicateIcon className="w-10 h-10" />
				<Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
					<span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">Copy text to clipboard</span>
				</Tooltip>
			</Shortcut>
			<Shortcut className="p-1 rounded-lg cursor-pointer hover:bg-blue-200 hover:text-blue-700 relative group flex flex-col items-center group text-gray-300"
			onClick={() => {
				const blob = new Blob([output || code || outputs], { type: "text/plain;charset=utf-8" });
				saveAs(blob, `${title}.doc`);
			}}>
				<DownloadIcon className="w-10 h-10" />
				<Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
					<span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">Download as Word Document</span>
				</Tooltip>
			</Shortcut>
			<div className="flex-1"></div>
			<Shortcut className="p-1 rounded-lg cursor-pointer hover:bg-red-200 hover:text-red-700 relative group flex flex-col items-center group text-gray-300" onClick={()=>store.reportToFeedback(output || code || outputs)}>
				<ExclamationCircleIcon className="w-10 h-10" />
				<Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
					<span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">Report issue with output</span>
				</Tooltip>
			</Shortcut>
		</div> : null}
		</>
	)
}))

const Tooltip = styled.div`
	display:none;
	white-space: nowrap;
`

const Shortcut = styled.div`
	&:hover ${Tooltip} {
		display: flex;
	}
`

function AOScores({ scores, gpt3TurboData, progressData }) {
	return (
	  <div className="aoscores-window">
		{/* Add your title text here */}
<h2 className="text-l  mb-3 text-center font-roboto">Numeric Results. Can Take Up To Two Minutes... </h2>
{!gpt3TurboData && progressData && (
        <div className="progress-data">
          {/* Display the progress data here */}
		  <div style={{ display: 'flex', justifyContent: 'center' }}>
  <p>The grade will be ready in five! {progressData.current} / {progressData.total}</p>
</div>
        </div>
      )}

      {/* Render your AO scores here */}
      {gpt3TurboData && (
        <div className="gpt3turbo-data">
          {/* Display the GPT-3.5 Turbo data here */}
          <p>
            Assessment Objective Scores: {gpt3TurboData.assessmentScores.join(", ")}
          </p>
          <p>Total Score: {gpt3TurboData.totalScore}</p>
          <p>Percentage likelihood this response has been AI generated: {gpt3TurboData.percentageLikelihood}%</p>
        </div>
      )}
    </div>
  );
}

function Outputs({ outputs, outputsColor, OutputsIcon }){
	
	return(
	<div className="whitespace-pre-wrap min-w-full py-4 text-gray-800 h-auto text-lg divide-y">
		<Typist
		stdTypingDelay={0}
		avgTypingDelay={7}
		className="divide-y"
		cursor={{
			show: false,
			blink: false,
			element: '|',
			hideWhenDone: true,
			hideWhenDoneDelay: 250,
		}}
		>
		
			{outputs.map((output, index) => 
				<div className="py-2 flex items-start" key={index}>
				<div className={`mr-4 flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full bg-${outputsColor ? outputsColor : "green"}-200 text-${outputsColor ? outputsColor : "green"}-600`}>
					{OutputsIcon === false ? 
						`${(index+1)}` : 
						OutputsIcon ? <OutputsIcon className={`h-4 w-4 text-${outputsColor ? outputsColor : "green"}-600`} aria-hidden="true" /> :
							<CheckIcon className={`h-4 w-4 text-${outputsColor ? outputsColor : "green"}-600`} aria-hidden="true" />}
				</div>
				{output}
			</div>)}
			
		</Typist> 

	</div>
	)
}


export default Output