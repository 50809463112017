import jobad from './jobad';
import codeInterpret from './interpret';
import helloworld from './helloworld';
import example from './example';
import handwriting from './Handwriting';
import handwritingtwo from './HandwritingConfig';
import isebthirteenwriting from './isebthirteenwriting';
import isebthirteenc from './isebthirteenc';
import isebthirteencten from './isebthirteencten';
import csseelevenwriting from './csseelevenwriting';
import cssecomp from './cssecomp';
import isebthirteencomp from './isebthirteencomp';
import isebelevenwriting from './isebelevenwriting';
import isebeleventwo from './isebeleventwo';
import isebeleventhree from './isebeleventhree';
import isebelevenfive from './isebelevenfive';
import isebelevenfour from './isebelevenfour';
import isebelevensix from './isebelevensix';
import alleyn from './alleyn';
import aldenhamcreative from './aldenhamcreative';
import aldenhamcreativeb from './aldenhamcreativeb';
import cssetwo from './cssetwo';
import cssethree from './cssethree';
import cssefour from './cssefour';
import cssefive from './cssefive';
import gencomptwo from './gencomptwo';
import gencompthree from './gencompthree';
import gencompfour from './gencompfour';
import gencompfive from './gencompfive';
import gencompsix from './gencompsix';
import alderley from './alderley';
import alderleyb from './alderleyb';
import elevencreative from './elevencreative';
import elevencreativeb from './elevencreativeb';
import eleventransaction from './eleventransaction';
import elevencontinue from './elevencontinue';
import ashville from './ashville';
import ashvilleb from './ashvilleb';
import babington from './babington';
import babingtonimage from './babingtonimage';
import bablake from './bablake';
import bablakeextract from './bablakeextract';
import bablakecontinue from './bablakecontinue';
import bancroft from './bancroft';
import battleabbey from './battleabbey';
import benenden from './benenden';
import benendenpoetry from './benendenpoetry';
import spgsdescribethree from './spgsdescribethree';
import spgssuggest from './spgssuggest';
import spgsetymology from './spgsetymology';
import spgschoose from './spgschoose';
import spgssurprise from './spgssurprise';
import spgsspeculate from './spgsspeculate';
import spgswritermeans from './spgswritermeans';
import spgsverdict from './spgsverdict';
import spgscreativeextract from './spgscreativeextract';
import thirteencheltenham from './thirteencheltenham';
import thirteencheltenhamten from './thirteencheltenhamten';
import clsg from './clsg';
import clsgb from './clsgb';
import highgateseven from './highgateseven';
import highgatefifteen from './highgatefifteen';
import highgatetwenty from './highgatetwenty';
import thirteenharrow from './thirteenharrow';
import thirteenharrowb from './thirteenharrowb';
import nlcscontinue from './nlcscontinue';
import nlcstransactional from './nlcstransactional';


const TOOLS = [

	handwriting,
	handwritingtwo,
	isebthirteenwriting,
	isebthirteencomp,
	isebthirteenc,
	isebthirteencten,
	cssetwo,
	cssethree,
	cssefour,
	cssefive,
	cssecomp,
	csseelevenwriting,
	isebeleventwo,
	isebeleventhree,
	isebelevenfour,
	isebelevenfive,
	isebelevensix,
	isebelevenwriting,
	alleyn,
	aldenhamcreative,
	aldenhamcreativeb,
	gencomptwo,
	gencompthree,
	gencompfour,
	gencompfive,
	gencompsix,
	alderley,
	alderleyb,
	elevencreative,
	elevencreativeb,
	elevencontinue,
	eleventransaction,
	ashville,
	ashvilleb,
	babington,
	babingtonimage,
	bablake,
	bablakeextract,
	bablakecontinue,
	bancroft,
	battleabbey,
	benenden,
	benendenpoetry,
	spgsdescribethree,
	spgswritermeans,
	spgssuggest,
	spgsetymology,
	spgsverdict,
	spgschoose,
	spgssurprise,
	spgsspeculate,
	spgscreativeextract,
	thirteencheltenhamten,
	thirteencheltenham,
	clsg,
	clsgb,
	highgateseven,
	highgatefifteen,
	highgatetwenty,
	thirteenharrow,
	thirteenharrowb,
	nlcscontinue,
	nlcstransactional,

]

export default TOOLS
