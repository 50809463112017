import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Form, Input, notification } from 'antd';
import './ResetPassword.css';


const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

@inject('store')
@observer
class ResetPassword extends Component {
  state = {
    isReset: false,
  };

  handleSubmit = async (values) => {
    const { store } = this.props;
    const { password, confirmPassword, resetToken } = values;

    if (password !== confirmPassword) {
      notification.error({
        message: 'Error',
        description: 'Passwords do not match',
      });
      return;
    }

    try {
      const result = await store.api.post('/auth/reset-password', { password, resetToken });
      if (result.status === 200) {
        this.setState({ isReset: true });
        notification.success({
          message: 'Password Reset',
          description: 'Your password has been reset.',
        });
      }
    } catch (error) {
      console.log("Error while resetting password:", error);
      notification.error({
        message: 'Error',
        description: 'An error occurred while resetting your password. Please try again later.',
      });
    }
  };

  render() {
    const { isReset } = this.state;
    if (isReset) {
      return <h3 style={{ textAlign: 'center', color: '#5B5B5B' }}>Your password has been reset!</h3>;
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#F8F8F8' }}>
        <Form {...formItemLayout} style={{ width: '40%', backgroundColor: '#FFFFFF', padding: '30px', borderRadius: '10px' }} onFinish={this.handleSubmit}>
          <Form.Item>
            <h2 style={{ textAlign: 'center', fontSize: '40px', color: '#5B5B5B' }}>Reset Password</h2>
          </Form.Item>
          <Form.Item
            name="resetToken"
            rules={[{ required: true, message: 'Please input your reset token!' }]}
            style={{ textAlign: 'center' }}
          >
            <Input className="reset-password-input" placeholder="Reset Token" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
            style={{ textAlign: 'center' }}
          >
            <Input.Password className="reset-password-input" placeholder="Password" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            rules={[{ required: true, message: 'Please confirm your password!' }]}
            style={{ textAlign: 'center' }}
          >
            <Input.Password className="reset-password-input" placeholder="Confirm Password" />
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit" style={{ width: '40%', height: '50px', fontSize: '20px' }}>
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default ResetPassword;
