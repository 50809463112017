import React, { Component } from 'react';

class FileUploadButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  async handleChange(event) {
    const file = event.target.files[0];
    console.log('Selected file:', file); // Add this line
    if (!file) return;

    this.setState({ isLoading: true });

    try {
      await this.props.onFileChange(file);
    } catch (error) {
      console.error('Error uploading file:', error);
    }

    this.setState({ isLoading: false });
  }

  render() {
    const { isLoading } = this.state;
    return (
      <div>
        <label htmlFor="file-upload" className="file-upload-label">
          {this.props.label}
        </label>
        <input
  type="file"
  id="file-upload"
  name="file" // Add this line
  className="file-upload-input"
  onChange={this.handleChange}
  accept=".pdf,.png,.jpg,.jpeg" // Accept PDF, PNG, JPG, and JPEG files
  disabled={isLoading}
/>
        {isLoading && <p>Loading...</p>}
      </div>
    );
  }
}

export default FileUploadButton;
