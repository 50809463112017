import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';
import './EmailVerification.css';

const EmailVerification = inject('store')(
  observer(({ store, history }) => {
    const resendVerificationEmail = async () => {
      try {
        // Check if 'profile' and 'email' properties exist
        if (store.profile && store.profile.email) {
          const userEmail = store.profile.email;
          // Pass the email as body of the POST request
          const response = await store.api.post('/user/resend-verification-email', { email: userEmail });

          console.log(response.data);
          alert('Verification email has been sent successfully.');
        } else {
          console.error("User's email is undefined.");
        }
      } catch (error) {
        console.error(error);
        alert('An error occurred while sending the verification email. Please try again later.');
      }
    };

    const logOut = async () => {
      try {
        await store.handleLogout(); // Use the existing logout method
        history.push('/login'); // Redirect to login page
      } catch (error) {
        console.error(error);
        alert('An error occurred while logging out. Please try again later.');
      }
    };

    return (
      <div className="email-verification">
        <h2 style={{ color: '#1890ff', textAlign: 'center', fontSize: '2em', fontWeight: 'bold' }}>We Need To Verify Your Email!</h2>
        <p>
          We sent a verification email to your inbox upon signup. Can't find it? Take a peek in your spam folder, it might be hiding there.
        </p>
        <p>
          No luck? No worries! Simply click the button below, and we'll send you another one right away.
        </p>
        <Button type="primary" onClick={resendVerificationEmail}>
          Resend Verification Email
        </Button>
        <p style={{ marginTop: '2em', fontSize: '1em', textAlign: 'center' }}>
          If you have just verified your account and are still seeing this message, you may need to log out, then log back in again.
        </p>
        <Button type="default" onClick={logOut}>
          Log Out
        </Button>
      </div>
    );
  })
);

export default withRouter(EmailVerification);
