import React, { useEffect, useRef, useState } from "react";
import { observer, inject } from "mobx-react";
import { Button } from "reactstrap";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_test_51LPLELC4gpAfet0jltzmm7ArcD0opPHQ36Cpj2OtLkb8LyviuBOwU50pxtfrgV4WOk8hmH60PFwnIBWigyRnYj8w002O07a0dI");

const PaymentDetails = inject('store')(observer((props) => {
    const [error, setError] = useState(null);

    const handleSubmit = async () => {
        console.log('handleSubmit called');
        const stripe = await stripePromise;
        console.log('stripePromise loaded');
      
        const response = await props.store.api.post('/user/create-setup-intent', { customerId: props.store.profile.customerId });
        console.log('Setup Intent response:', response);
      
        const clientSecret = response.data.client_secret;
        const intentId = response.data.id;
        console.log('Setup Intent created:', intentId, clientSecret);
      
        const elements = stripe.elements();
        const cardElement = elements.create('card');
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: props.store.profile.name,
            email: props.store.profile.email
          }
        });
      
        console.log('createPaymentMethod response:', error, paymentMethod);
      
        if (error) {
          console.log("Error:", error);
        } else {
          const { error } = await stripe.confirmCardSetup(`${intentId}_secret_${clientSecret}`, {
            payment_method: paymentMethod.id
          });
      
          console.log('confirmCardSetup response:', error);
      
          if (error) {
            console.log("Error:", error);
          } else {
            console.log("Card updated successfully!");
          }
        }
      };
      
    return (
        <div>
            <h3>Payment Details</h3>
            <Button onClick={handleSubmit}>Update Card</Button>
            {error && <div className="text-danger mt-3">{error}</div>}
        </div>
    );
}));

export default PaymentDetails;
