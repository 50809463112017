import React, { Component, useState } from "react"; // Import useState
import { withRouter } from "react-router-dom"; // Import withRouter
import { CheckIcon } from "@heroicons/react/outline";
import { observer, inject } from "mobx-react";

@inject("store")
@observer
class EntryPromptBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: "",
      time: 20 * 60, // 20 minutes in seconds
      isTimerActive: false,
    };

    this.interval = null;
  }

  startTimer = () => {
    if (!this.state.isTimerActive && this.state.time > 0) {
      this.setState({ isTimerActive: true });
      this.interval = setInterval(() => {
        this.setState(
          (prevState) => ({
            time: prevState.time - 1,
          }),
          () => {
            if (this.state.time <= 0) {
              clearInterval(this.interval);
              this.setState({ isTimerActive: false });
              alert("Time is up!");
            }
          }
        );
      }, 1000);
    }
  };

  formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  downloadFile = () => {
    // Assuming your file is located in the public directory
    // and your app is served from the root
    const link = document.createElement("a");
    link.href = `${process.env.PUBLIC_URL}/spgs1.pdf`; // Adjust the path as needed
    link.setAttribute("download", "spgs1.pdf"); // Set the filename for download
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  generateIeltsAcademic1PracticePaper = () => {
    const topicImagePairs = [
      {
        topic:
          "The graph above shows the number of visitors to four international museums over a period of one year. Summarize the information by selecting and reporting the main features, and make comparisons where relevant.        .",
        image: "/example1.png",
      },
      {
        topic:
          "The pie charts above show the percentage distribution of water usage in a typical household in two different countries, Country A and Country B, in the year 2023. Summarize the information by selecting and reporting the main features, and make comparisons where relevant.",
        image: "/example2.png",
      },

      // Additional pairs
    ];

    const randomIndex = Math.floor(Math.random() * topicImagePairs.length);
    const selectedPair = topicImagePairs[randomIndex];

    // Assuming the image is stored in the public directory and accessible
    const imageUrl = `${process.env.PUBLIC_URL}${selectedPair.image}`;
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result;

          // Updating the state with the new image URL and base64 data
          this.setState(
            {
              imageUrl: imageUrl,
              imageBase64: base64data, // Save the Base64-encoded image data in state
            },
            () => {
              // Log the state to verify it's correctly updated
              console.log("Updated state with image data:", this.state);
            }
          );

          // Update the prompt with the selected topic
          this.props.prompt.prompts[0].value = selectedPair.topic;
        };
        reader.readAsDataURL(blob);
      });
  };

  ieltsacademic2 = () => {
    const topics = [
      //1
      {
        topic:
          "With the rapid advancement of technology, some argue that the traditional classroom setting is becoming obsolete. To what extent do you agree or disagree with this statement? Provide reasons for your answer and include any relevant examples from your own knowledge or experience.",
        opening: "",
      },

      //100
      {
        topic:
          "The push for smart cities incorporates technology into urban development for efficiency and sustainability. Analyze the potential social implications of living in highly digitized urban environments, focusing on aspects such as privacy, community, and human interaction.",
        opening: "",
      },
      // Additional topics can be added here
    ];

    const randomIndex = Math.floor(Math.random() * topics.length);
    const randomTopic = topics[randomIndex];

    this.props.prompt.prompts[0].value = randomTopic.topic;
    this.props.prompt.prompts[1].value = randomTopic.opening;
  };

  ieltsgeneral1 = () => {
    const papers = [
      //1
      {
        topic:
          "Your child's school is organizing a cultural festival and is looking for parents to help in various capacities. Write a letter to the event coordinator volunteering your services. In your letter:\n- Mention your name and your child’s name and grade.\n- Explain why you are interested in volunteering for the cultural festival.\n- Describe the skills or experiences you have that make you a suitable volunteer for the event.",
        opening: "",
      },

      //100
      {
        topic:
          "You wish to enroll your child in an after-school program but require additional information. Write a letter to the program organizer. In your letter:\n- Introduce yourself and your child.\n- Express your interest in the program.\n- Request detailed information about program activities, schedule, and fees.",
        opening: "",
      },
    ];

    const randomIndex = Math.floor(Math.random() * papers.length);
    const randomTopic = papers[randomIndex];

    this.props.prompt.prompts[0].value = randomTopic.topic;
    this.props.prompt.prompts[1].value = randomTopic.opening;
  };

  ieltsgeneral2 = () => {
    const storyPrompts = [
      //1
      {
        topic:
          "With the advance of technology, many jobs traditionally performed by humans are being automated. Discuss the advantages and disadvantages of automation in the workplace. What measures should be taken to mitigate any negative impacts?",
        opening: "",
      },

      //100
      {
        topic:
          "The global trend towards cashless societies has accelerated. Discuss the benefits and challenges of moving towards cashless transactions and the impact on different sectors of society.",
        opening: "",
      },
      // Additional story prompts can be added here
    ];

    const randomIndex = Math.floor(Math.random() * storyPrompts.length);
    const randomPrompt = storyPrompts[randomIndex];

    this.props.prompt.prompts[0].value = randomPrompt.topic;
    this.props.prompt.prompts[1].value = randomPrompt.opening;
  };

  hkdse2 = () => {
    const storyPrompts = [
      //1
      {
        topic:
          "Hong Kong's culinary scene is a melting pot where East meets West, offering a unique fusion cuisine that is renowned worldwide. Write an article for a global food magazine where you introduce and explain the concept of fusion cuisine in Hong Kong. Highlight specific dishes that embody this cultural blend and argue why tourists should not leave Hong Kong without trying them.",
        opening: "",
      },

      //100
      {
        topic:
          "Hong Kong's street food vendors, known as 'Dai Pai Dongs', are essential to the city's culinary culture but face challenges in modern times. As a cultural anthropologist, write a research paper examining the social and economic significance of Dai Pai Dongs in Hong Kong. Discuss the impact of urban development on these vendors and suggest measures to preserve this culinary heritage.",
        opening: "",
      },
    ];

    const randomIndex = Math.floor(Math.random() * storyPrompts.length);
    const randomPrompt = storyPrompts[randomIndex];

    this.props.prompt.prompts[0].value = randomPrompt.topic;
    this.props.prompt.prompts[1].value = randomPrompt.opening;
  };

  render() {
    const { currentPrompt, disabled, index, location } = this.props;
    const { imageUrl, time, isTimerActive } = this.state;
    let hidden = currentPrompt !== index;

    return (
      <div
        className={`align-bottom bg-white md:rounded-md text-left overflow-hidden transform transition-all sm:align-middle transition hover:shadow-md shadow-2xl focus:shadow-2xl md:mb-8 ${
          hidden ? "hidden" : ""
        }`}
      >
        <div className="px-6 py-6">
          <div className="flex items-center">
            <div
              className={`flex-shrink-0 inline-flex items-center justify-center md:h-12 md:w-12 h-6 w-6 rounded-full bg-${
                !disabled ? "green" : "gray"
              }-300 sm:mx-0 sm:h-10 sm:w-10 ${
                !disabled ? "bg-green-300" : "bg-gray-300"
              } `}
            >
              <CheckIcon
                className={`h-3 w-3 md:h-6 md:w-6 ${
                  !disabled ? "text-green-700" : "text-gray-500"
                } text-${!disabled ? "green-700" : "gray-500"}`}
                aria-hidden="true"
              />
            </div>

            <div className="mt-0 ml-4 text-left">
              <div
                as="h3"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                {this.props.prompt.title || "Entry Text"}
              </div>

              <p className="text-sm text-gray-500">
                {this.props.prompt.desc || "Write a small bit of text"}
              </p>
            </div>
          </div>
          {/* Conditionally render buttons based on the current pathname */}
          <div className="flex justify-center mt-4">
            {/* Existing component structure */}

            {/* Additional button for paths including 'zip' */}
            {location.pathname.includes("spgs") && (
              <button
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full bg-gradient-to-r from-green-400 to-green-400 hover:from-green-400 hover:to-green-600 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={this.downloadFile}
              >
                Download Past Paper for Reference
              </button>
            )}

            {/* Rest of the component */}
          </div>{" "}
          <div className="flex justify-center mt-4">
            {location.pathname.includes("/ai/writing/ieltsacademic2") && (
              <button
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full bg-gradient-to-r from-green-400 to-green-400 hover:from-green-400 hover:to-green-600 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={this.ieltsacademic2}
              >
                Generate a Practice Paper
              </button>
            )}
            {location.pathname.includes("/ai/writing/ieltsgeneral2") && (
              <button
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full bg-gradient-to-r from-green-400 to-green-400 hover:from-green-400 hover:to-green-600 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={this.ieltsgeneral2}
              >
                Generate a Practice Paper
              </button>
            )}
            <div>
              {/* Other component content */}
              {location.pathname.includes("/ai/writing/ieltsacademic1") && (
                <>
                  <button
                    className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full bg-gradient-to-r from-green-400 to-green-400 hover:from-green-400 hover:to-green-600 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mr-2"
                    onClick={this.generateIeltsAcademic1PracticePaper}
                  >
                    Generate a Practice Paper
                  </button>
                  <button
                    className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full bg-gradient-to-r from-blue-400 to-blue-600 hover:from-blue-600 hover:to-blue-800 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={this.startTimer}
                  >
                    Start Timer
                  </button>
                  {isTimerActive && (
                    <div className="text-xl font-semibold mt-4">
                      {this.formatTime(time)}
                    </div>
                  )}
                  {imageUrl && (
                    <img src={imageUrl} alt="Practice Paper Image" />
                  )}
                </>
              )}

              {/* Other component content */}
            </div>
            {location.pathname.includes("/ai/writing/ieltsgeneral1") && (
              <button
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full bg-gradient-to-r from-green-400 to-green-400 hover:from-green-400 hover:to-green-600 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={this.ieltsgeneral1}
              >
                Generate a Practice Paper
              </button>
            )}

            {location.pathname.includes("/ai/writing/hkdsetwo") && (
              <button
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full bg-gradient-to-r from-green-400 to-green-400 hover:from-green-400 hover:to-green-600 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={this.hkdse2}
              >
                Generate a Practice Paper
              </button>
            )}
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}

// Wrap the EntryPromptBase with withRouter to inject router props
const EntryPrompt = withRouter(EntryPromptBase);

export default EntryPrompt;
