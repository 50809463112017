import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import Modal from 'react-modal';
import { DocumentTextIcon, CogIcon } from '@heroicons/react/outline'
import './UserHistory.css';


@inject('store')
@observer
class UserHistory extends Component {
    state = {
        history: [],
        groupedHistory: {},
        loading: true,
        selectedHistory: null,
        modalIsOpen: false
    };


    colorGradient(index, total) {
        const minOpacity = 0.2;
        const maxOpacity = 0.5;
        const opacityRange = maxOpacity - minOpacity;
    
        const fraction = ((total - index) / total) * opacityRange + minOpacity; // Opacity starts from 0.7
    
        // A lighter blue in rgb
        const lightBlue = [68, 108, 179];
    
        return `rgba(${lightBlue.join(",")}, ${fraction})`;
    }
    componentDidMount() {


        
        const url = 'https://ent.topmarks.ai/api/user/fetch-user-history';
        const jwtToken = this.props.store.jwtToken;

        if (typeof jwtToken !== 'string' || jwtToken === '') {
            console.error('jwtToken is missing or not a string in the store:', jwtToken);
            this.setState({ loading: false });
            return;
        }

        const headers = {
          'Authorization': `Bearer ${jwtToken}`
        };

        fetch(url, { 
          method: 'GET',
          headers: headers
        })
        .then(response => {
                if (response.ok) {
                    return response.json();
                }
                return response.text()
                    .then(text => {
                        let errorMessage = 'Error: ';

                        switch(response.status) {
                            case 401:
                                errorMessage += 'Unauthorized';
                                break;
                            case 403:
                                errorMessage += 'Forbidden';
                                break;
                            case 404:
                                errorMessage += 'Not Found';
                                break;
                            default:
                                errorMessage += `Unknown (${response.status})`;
                        }

                        throw new Error(errorMessage);
                    });
            })
            .then(data => {
                const filteredHistory = data.userHistory.filter(item => {
                    const itemDate = moment(item.created);
                    const filterDate = moment('July 2023', 'MMMM YYYY');
                    return itemDate.isSameOrAfter(filterDate);
                });
            
                this.setState({ history: filteredHistory, loading: false }, this.groupHistoryByMonth);
                        })
            .catch(error => {
                console.error('Failed to fetch user history:', error);
                this.setState({ loading: false });
            });
    }

    downloadAsWord = () => {
        const { selectedHistory } = this.state;

        fetch('https://ent.topmarks.ai/api/user/download-word', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                input: selectedHistory.input,
                output: selectedHistory.output
            })
        })
        .then(res => res.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'history.docx';
            a.click();
        });
    };


    groupHistoryByMonth() {
        const groupedHistory = this.state.history.reduce((acc, item) => {
            const monthYearKey = moment(item.created).format("MMMM YYYY");
            if (!acc[monthYearKey]) {
                acc[monthYearKey] = [];
            }
            acc[monthYearKey].push(item);
            return acc;
        }, {});
    
        // sort items in each month in descending order (newest first)
        for (let month in groupedHistory) {
            groupedHistory[month].sort((a, b) => moment(b.created).toDate() - moment(a.created).toDate());
        }
    
        this.setState({ groupedHistory });
    }

    handleHistoryItemClick = (historyItem) => {
        this.setState({ selectedHistory: historyItem, modalIsOpen: true });
    }
    
    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    renderHistoryDetails() {
        const { selectedHistory, modalIsOpen } = this.state;
        if (!selectedHistory) {
            return null;
        }
    
        const bubbleStyle = {
            fontSize: '1em',
            color: '#000000',
            padding: '20px',
            borderRadius: '25px',
            backgroundColor: 'white',
            boxShadow: '0 10px 15px rgba(0, 0, 0, 0.1)',
            margin: '20px 0',
            border: '1px solid rgba(0, 0, 0, 0.1)',
            textAlign: 'left',
            whiteSpace: 'pre-wrap',
            transition: 'all 0.5s ease', // Longer transition for a smoother effect
            ':hover': { // Increase size when hovered over
                transform: 'scale(1.02)',
            }
        };
    
        const titleStyle = {
            fontSize: '1.2em',
            padding: '10px',
            borderRadius: '15px',
            boxShadow: '0 5px 10px rgba(0, 0, 0, 0.1)',
            margin: '10px 0',
            textAlign: 'center',
            whiteSpace: 'pre-wrap',
            transition: 'all 0.5s ease', // Longer transition for a smoother effect
            color: 'white',
            background: 'linear-gradient(to right, #000000, #434343)', // Gradient background
            fontFamily: 'Arial, sans-serif', // Change the font
            textShadow: '2px 2px 4px #000000', // Add text shadow for a 3D effect
            ':hover': { // Change color when hovered over
                color: '#dddddd',
            }
        };
        return (
            <Modal 
                isOpen={modalIsOpen} 
                onRequestClose={this.closeModal} 
                contentLabel="Selected History Details"
                style={{
                    content: {
                        width: '60%', // Adjust the width as you like
                        margin: '0 auto', // Center the modal on the screen
                        // ... and any other styles you'd like to apply to the modal content
                    },
                    overlay: {
                        // ... and any styles you'd like to apply to the modal overlay
                    }
                }}
            >
                <button style={{ float: 'right' }} onClick={this.downloadAsWord}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
    <p style={{ marginRight: '10px' }}>Download to Word</p>
    <DocumentTextIcon className="h-7 w-7" aria-hidden="true" />
</div>                </button>
                <button onClick={this.closeModal}>Close</button>
                <div className="title">Question & Student Response</div>
                <div className="bubble" 
                    dangerouslySetInnerHTML={{__html: selectedHistory.input}}
                ></div>
                <div className="title">Feedback</div>
                <div className="bubble" 
                    dangerouslySetInnerHTML={{__html: selectedHistory.output}}
                ></div>
            </Modal>
        );
        
}
    
    

renderLoad() {
    return <div className="loading">Loading...</div>;
}


    renderError() {
        return <h2>Error loading user histories</h2>;
    }

    renderNoHistory() {
        return <h2 className="no-history">No previous enquiries found</h2>;
    }
    

    renderHistory() {
        // Obtain the keys and sort them.
        const monthYears = Object.keys(this.state.groupedHistory)
            .sort((a, b) => moment(b, 'MMMM YYYY').toDate() - moment(a, 'MMMM YYYY').toDate());
    
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <h2 style={{
                        fontSize: '2em',
                        color: '#FFFFFF',
                        padding: '0px 20px',
                        borderRadius: '20px',
                        backgroundColor: '#434343',
                        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)',
                        border: '1px solid #FFFFFF',
                        margin: '20px 0',
                        display: 'inline-block',
                        textAlign: 'center'
                    }}>
                        Past Enquiries
                    </h2>
                </div>
                {monthYears.map((monthYear, index) => (
                    <div key={monthYear}>
                        <h3 style={{
                            fontSize: '1.5em',
                            color: '#000000',
                            padding: '10px 20px',
                            borderRadius: '20px',
                            backgroundColor: this.colorGradient(index, monthYears.length - 1), // Use dynamic background color.
                            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)',
                            margin: '40px 0 10px 0',
                            border: '1px solid #000000',
                            display: 'inline-block',
                            textAlign: 'center'
                        }}>
                            {monthYear}
                        </h3>
                 
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
    {this.state.groupedHistory[monthYear].map((historyItem, index) => (
        <div key={index} 
            style={{
                border: '1px solid black', 
                padding: '10px', 
                margin: '10px', 
                cursor: 'pointer', 
                borderRadius: '10px',
                width: '18%',
                textAlign: 'center',
                backgroundColor: '#ffffff', // White background.
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)', // Add shadow to make it looks like bulging out.
                transition: 'all 0.3s ease-in-out', // Add transition effect when hover.
                wordWrap: 'break-word', // Add break-word to prevent words from overflowing.
            }}
            onMouseEnter={(e) => {
                // Change box shadow and transform scale when hovered over to create bulging effect.
                e.currentTarget.style.boxShadow = '0 5px 15px rgba(0, 0, 0, 0.3)';
                e.currentTarget.style.transform = 'scale(1.05)';
            }}
            onMouseLeave={(e) => {
                // Reset to original box shadow and transform scale when mouse leaves.
                e.currentTarget.style.boxShadow = '0 2px 5px rgba(0, 0, 0, 0.15)';
                e.currentTarget.style.transform = 'scale(1)';
            }}
            onClick={() => this.handleHistoryItemClick(historyItem)}
        >
            <div>
                <CogIcon className="h-6 w-6 inline-block mr-2" aria-hidden="true" />
                <p className="inline-block">{"" + historyItem.api.split("/").pop()}</p>
            </div>
            <div>
                <p className="inline-block">{moment(historyItem.created).format("MMMM D")}</p>
            </div>
        </div>
    ))}
</div>
                        </div>
                ))}
            </div>
        );
    }
    

    render() {
        if(this.state.loading) {
            return this.renderLoad();
        }
    
        if(!this.state.history.length) {
            return this.renderNoHistory();
        }
    
        return (
            <div className="container">
                {this.renderHistory()}
                {this.renderHistoryDetails()}
            </div>
        );
    }
    
}

export default UserHistory;