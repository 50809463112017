// tools/HandwritingConfig.js
import { AcademicCapIcon } from '@heroicons/react/solid';

const handwritingConfig = {
  title: "Turn Handwritten Scripts into Text!",
  desc: "Use this tool to convert PDFs of handwritten scripts into fully editable text (that we can then mark!)",
  category: "Handwriting",
  Icon: AcademicCapIcon,
  permissions: ['user'],
  fromColor: "blue-400",
  toColor: "blue-600",
  to: "/ai/writing/handwritingtwo",
  api: "/ai/writing/handwritingtwo",
  output: {
    title: "Turn Handwritten Scripts into Text",
    desc: "Please wait as we decode the handwriting!",
  },
  prompts: [
    {
      title: "Use this tool to convert PDFs of handwritten scripts into fully editable text (that we can then mark!)",
      desc: "Upload the essay response in PDF format. Make sure the images are clear, and that the file is no larger than 5 pages",
      prompts: [
        {
          title: "Context",
          attr: "essay_title",
          value: "",
          placeholder: "e.g. The student has written an essay entitled 'How far can Macbeth be considered an intrinsically violent individual?",
          label: "Essay Title",
          type: "textarea",
          maxLength: 150,
          required: true,
          error: "",
          example: "The student has written an essay entitled 'How far can Macbeth be considered an intrinsically violent individual?'",
        },
        {
          title: "Upload a PDF.",
          attr: "pdf_file",
          value: "",
          label: "Upload your PDF file",
          type: "file",
          required: true,
          error: "",
        },
      ],
      example: {
        output: "Percentage  \n\nEND OF RESPONSE.",
      },
    },
  ],
};

export { handwritingConfig };
