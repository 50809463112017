
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import './PasswordReset.css';
import { Button, Form, Input, notification } from 'antd';


@inject('store')
@observer
class PasswordReset extends Component {
  state = {
    email: '',
    message: '',
    errorMessage: ''
  };

  handleSubmit = async (event) => {
    try {
      event.preventDefault();
      
      // call notification API here
      notification.open({
        message: 'Thank you! If you have an account with us, you will soon receive an email with instructions to reset your password.'
      });
  
      const { store } = this.props;
      const response = await store.api.post('/auth/reset-password', {
        email: this.state.email
      });
      if (response.status === 200 && this.state.email !== '') {
        let data = await response.json();
        if (data.message) {
          this.setState({ message: 'Thank you! If you have an account with us, you will soon receive an email with instructions to reset your password.' });
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.message) {
        this.setState({ errorMessage: err.response.data.message });
      }
    }
  };
  

  handleChange = (event) => {
    this.setState({ email: event.target.value });
  };

  render() {
    return (
      <div className="password-reset-container">
        <form className="password-reset-form" onSubmit={this.handleSubmit}>
          <div className="password-reset-ornamental-break"></div>
          <p className="password-reset-text">
            Kindly provide your email address and we will promptly send you a
            secure link to reset your password.
          </p>
          <input
            className="password-reset-email-input"
            type="email"
            placeholder="Email"
            value={this.state.email}
            onChange={this.handleChange}
          />
          <button className="password-reset-submit-button" type="submit">
            Submit
          </button>
        </form>
        {this.state.message && (
          <div className="messageContainer">
            <p className="message">{this.state.message}</p>
          </div>
        )}
        {this.state.errorMessage && (
          <div className="errorMessageContainer">
            <p className="errorMessage">{this.state.errorMessage}</p>
          </div>
        )}
      </div>
    );
  }
}

export default PasswordReset;