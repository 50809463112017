const connectedSockets = {};

function setCurrentSocketId(socketId) {
  sessionStorage.setItem("currentSocketId", socketId);
}

function getCurrentSocketId() {
  return sessionStorage.getItem("currentSocketId");
}

module.exports = {
  connectedSockets,
  setCurrentSocketId,
  getCurrentSocketId,
};