import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import './Status.css';

const Status = ({ priceId, currentPeriodEnd, isCancelled }) => {
  const [status, setStatus] = useState('');
  const [subscriptionEndDate, setSubscriptionEndDate] = useState('');
  const [subscriptionEndTime, setSubscriptionEndTime] = useState('');

  useEffect(() => {
    if (isCancelled) {
      setStatus('Subscription cancelled');
      setSubscriptionEndDate('');
      setSubscriptionEndTime('');
      return;
    }

    if (!currentPeriodEnd) {
      setSubscriptionEndDate('N/A');
      setSubscriptionEndTime('N/A');
    } else {
      const subscriptionEndValue = new Date(currentPeriodEnd);
      if (subscriptionEndValue.toString() === "Invalid Date") {
        setSubscriptionEndDate('N/A');
        setSubscriptionEndTime('N/A');
      } else {
        setSubscriptionEndDate(subscriptionEndValue.toLocaleDateString());
        setSubscriptionEndTime(subscriptionEndValue.toLocaleTimeString());
      }
    }

    switch (priceId) {
      case 'STRIPEPRODUCTFREE':
        setStatus('Free');
        break;
      case 'STRIPEPRODUCTENTRY':
        setStatus('Entry (£20 per month)');
        break;
      case 'STRIPEPRODUCTPRO':
        setStatus('Pro (£40 per month)');
        break;
      default:
        setStatus('Unknown');
        break;
    }
  }, [priceId, currentPeriodEnd, isCancelled]);

  return (
    <div className="status-container">
      <div className="status-bubble">
        <p className="status-text">Current Pricing Tier: {status}</p>
        {isCancelled ? (
          <p className="status-text">Subscription Ends On: Subscription cancelled</p>
        ) : (
          <p className="status-text">Subscription Ends On: {subscriptionEndDate} at {subscriptionEndTime}</p>
        )}
      </div>
    </div>
  );
};

export default observer(Status);
